<div class="modal-header" *ngIf="isEditMode">
    <div class="row justify-content-between align-items-center">
        <div class="col-md-auto">
            <h5 class="mb-0">CLAIM ID: {{claimId}} | POLICY NO : {{policyNumber}} | Reported Date: {{policyStartDate | date: 'dd/MM/yyyy' }}</h5>
        </div>
        <div class="col-md-auto">
            <span class="material-symbols-outlined cursor-pointer" (click)="closeDialog(true)">
                close
            </span>
        </div>
    </div>
</div>
<div class="row d-flex justify-content-center align-items-center" >
    <div class="col-md-12">
        <div class="card">            
            <div class="card-body">
                <div class="row mb-3 input-without-lbl-gray">
                    <div class="col-md-4">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Total Reserve :</label>
                            <label class="font-weight-semibold">€{{totalReserve | currency:' ':'symbol':'0.2-2'}}</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Total Settled :</label>
                            <label class="font-weight-semibold">€{{totalSettled| currency:' ':'symbol':'0.2-2'}}</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="gray-input-box d-flex justify-content-between">
                            <label>Total Claims :</label>
                            <label class="font-weight-semibold">€{{totalClaims| currency:' ':'symbol':'0.2-2'}}</label>
                        </div>
                    </div>
                </div>                
                <div class="position-relative">
                    <div class="step-indicators">
                        <div class="step" *ngFor="let step of steps; let i = index" [class.active]="currentTab === i">
                            <div (click)="ChangeStep(i)">
                                {{
                                step }}
                            </div>
                        </div>
                    </div>
                    <b *ngIf="!isQuote" style="font-size: large; font-size: large; position: absolute;  right: 0; top: 0;">
                        <span style="color: tomato; cursor:pointer;" (click)="Cancele()">{{policyNumber}}</span>
                    </b> 
                    <b *ngIf="isQuote" style="font-size: large; font-size: large; position: absolute;  right: 0; top: 0;">
                        <span style="color: tomato; cursor:pointer;">{{policyNumber}}</span>
                    </b>                                       
                    <b *ngIf="isEditMode"
                        style="font-size: large; font-size: large; position: absolute;  right: 0; top: 0;">
                        <span style="color: tomato; ">{{policyNumber}}</span>
                    </b>
                </div>
                <div class="row mt-3" *ngIf="policyStartDate>maxDate">
                    <div class="col-lg-12">
                        <div class="alert alert-warning">
                            The date range for all entries should be from the current date to the policy's expiration date
                        </div>
                    </div>
                </div>
                <!-- <div id="regForm"> -->
                <div id="all-steps">
                    <!-- Claim Summary Tab -->
                    <div class="tab" *ngIf="currentTab === 0">
                        <h4>Claim Summary</h4>
                        <form novalidate [formGroup]="claimForm">                            
                            <div class="row gy-4">

                                <div class="col-md-3  position-relative">
                                    <label>Claim Status</label>
                                    <kendo-combobox tabindex="-1"  [openOnFocus]="claimStatusId" #claimStatusId class="w-100" placeholder="Claim Status" [data]="claimStatusList"
                                        formControlName="claimStatus" [textField]="'text'" [valuePrimitive]="true"
                                        [valueField]="'value'">
                                    </kendo-combobox>
                                    <div class="validation-msg-label"
                                        *ngIf="claimForm.controls?.claimStatus.touched && claimForm.controls?.claimStatus.errors?.required">
                                        Required</div>
                                </div>
                                <div class="col-md-3  position-relative" *ngIf="
                                        claimForm.controls?.claimStatus.value==2">
                                    <label>Closed Date</label>
                                    <kendo-datepicker  class="w-100" [format]="'dd/MM/yyyy'" formControlName="dateClosed"
                                        [min]="minDate" [max]="maxDate"></kendo-datepicker>
                                    <div class="validation-msg-label" *ngIf="
                            claimForm.controls?.dateClosed.touched &&
                            claimForm.controls?.dateClosed.errors?.required">
                                        Required
                                    </div>
                                </div>

                                <div class="col-md-3  position-relative">
                                    <label>Incident Date</label>
                                    <kendo-datepicker class="w-100" [format]="'dd/MM/yyyy'"
                                        formControlName="incidentDate" [min]="minDate"
                                        [max]="maxDate"></kendo-datepicker>
                                    <div class="validation-msg-label" *ngIf="
                            claimForm.controls?.incidentDate.touched &&
                            claimForm.controls?.incidentDate.errors?.required">
                                        Required
                                    </div>
                                    <div class="validation-msg-label"
                                        *ngIf="
                                    claimForm.controls?.incidentDate.errors?.minError||claimForm.controls?.incidentDate.errors?.maxError">
                                        Invalid Date
                                    </div>
                                </div>
                                <div class="col-md-3  position-relative">
                                    <label>Nature of Claim</label>
                                    <kendo-combobox [openOnFocus]="natureofClaim" #natureofClaim class="w-100" (valueChange)="onChangeNatureOfClaim($event)"
                                        placeholder="Nature of Claim" [data]="claimNatureList"
                                        formControlName="natureofClaim" [textField]="'text'" [valuePrimitive]="true"
                                        [valueField]="'value'">
                                    </kendo-combobox>
                                    <div class="validation-msg-label"
                                        *ngIf="claimForm.controls?.natureofClaim.touched && claimForm.controls?.natureofClaim.errors?.required">
                                        Required</div>
                                </div>
                                <div class="col-md-3 position-relative">
                                    <label>Peril</label>
                                    <kendo-combobox [openOnFocus]="peril" #peril class="w-100" placeholder="Peril" [data]="claimPerilList"
                                        formControlName="peril" [textField]="'text'" [valuePrimitive]="true"
                                        [valueField]="'value'">
                                    </kendo-combobox>
                                    <div class="validation-msg-label"
                                        *ngIf="claimForm.controls?.peril.touched && claimForm.controls?.peril.errors?.required">
                                        Required</div>
                                </div>
                                <div class="col-md-6">
                                    <label>Incident Location</label>
                                    <input type="text" class="form-control" formControlName="incidentLocation"
                                        maxlength="500">
                                </div>
                                <div class="col-md-2">
                                    <label>Policy Duration</label>
                                    <div class="d-flex gap-3 align-items-center">
                                        <div class="badge bg-primary">{{policyStartDate | date: 'dd/MM/yyyy' }} -
                                            {{policyEndDate | date: 'dd/MM/yyyy' }}</div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <label>Policy Version</label>
                                    <div class="d-flex gap-3 align-items-center">
                                        <div class="font-weight-semibold">{{policyId}}</div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Insurer</label>
                                    <div class="d-flex gap-3 align-items-center">
                                        <div class="font-weight-semibold">{{Insurer}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <hr>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Description</label>
                                    <textarea name="" rows="5" id="" maxLength="500" maxlength="500"
                                        class="form-control form-control-textarea"
                                        formControlName="description"></textarea>
                                </div>
                                <div class="col-md-6">
                                    <label>Additional Info</label>
                                    <textarea name="" rows="5" id="" maxLength="500"
                                        class="form-control form-control-textarea"
                                        formControlName="additionalInfo"></textarea>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Claimant Details Tab -->
                    <div class="tab" *ngIf="currentTab === 1">
                        <h4>Claimant Details</h4>

                        <form novalidate [formGroup]="claimClaimantsForm">

                            <div class="border bg-light-gray mb-3 br-10 p-4">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="font-weight-semibold text-decoration-underline mb-3">
                                                    Claimant
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="claimantName" placeholder="Claimant Name"
                                                        maxlength="500">
                                                </div>
                                                <div class="form-group">
                                                    <textarea name="" rows="5" id="" maxlength="500"
                                                        class="form-control form-control-textarea"
                                                        formControlName="claimantAddress"
                                                        placeholder="Claimant Address"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="font-weight-semibold text-decoration-underline mb-3">
                                                    Insurer
                                                </div>
                                                <div class="form-group">
                                                    <div class="row gy-3 align-items-center">
                                                        <div class="col-md-3">
                                                            <label class="mb-0">Ref</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <input type="text" class="form-control"
                                                                formControlName="insurerRef" maxlength="500">
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label class="mb-0">Handler</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <input type="text" class="form-control"
                                                                formControlName="insurerHandler" maxlength="500">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="font-weight-semibold text-decoration-underline mb-3">
                                                    Loss Adjuster
                                                </div>
                                                <div class="form-group">
                                                    <div class="row gy-3 align-items-center">
                                                        <div class="col-md-3">
                                                            <label class="mb-0">Ref</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <input type="text" class="form-control"
                                                                formControlName="lossAdjusterRef" maxlength="500">
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label class="mb-0">Company</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <input type="text" class="form-control"
                                                                formControlName="lossAdjusterCompany" maxlength="500">
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label class="mb-0">Handler</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <input type="text" class="form-control"
                                                                formControlName="lossAdjusterHandler" maxlength="500">
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label class="mb-0">Email</label>
                                                        </div>
                                                        <div class="col-md-9 position-relative">
                                                            <input type="text" class="form-control" maxlength="500"
                                                                formControlName="lossAdjusterEmail">
                                                            <div class="validation-msg-label"
                                                                *ngIf="(!claimClaimantsForm.controls.lossAdjusterEmail.errors?.required  && claimClaimantsForm.controls.lossAdjusterEmail.invalid)||(claimClaimantsForm.controls.lossAdjusterEmail.touched  && claimClaimantsForm.controls.lossAdjusterEmail.errors?.whitespace)">
                                                                Invalid EmailId
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="font-weight-semibold text-decoration-underline mb-3">
                                                    Financial
                                                </div>
                                                <div class="form-group">
                                                    <div class="row gy-3 align-items-center">
                                                        <div class="col-md-3">
                                                            <label class="mb-0">Reserve</label>
                                                        </div>
                                                        <div class="col-md-9 position-relative">
                                                            <div class="input-group ">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">
                                                                        <img src="/assets/images/euro-currency-symbol.svg"
                                                                            alt="Euro">
                                                                    </span>
                                                                </div>
                                                                <input type="text" class="form-control" twodecimalOnly
                                                                    maxlength="8" min="1" max="99999999"
                                                                    formControlName="reserveAmt">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label class="mb-0">Payments</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div class="input-group ">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">
                                                                        <img src="/assets/images/euro-currency-symbol.svg"
                                                                            alt="Euro">
                                                                    </span>
                                                                </div>
                                                                <input type="text" class="form-control" twodecimalOnly
                                                                    maxlength="8" min="1" max="99999999"
                                                                    formControlName="settledAmt">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="row">
                                                <div class="col-md-3"></div>
                                                <!-- <div class="col-md-4"><button class="btn btn-sm btn-danger" (click)="AddNewClaimant()">Save</button></div> -->
                                                <div class="col-md-4"><button class="btn btn-primary"
                                                        (click)="AddNewClaimant()">Save</button></div>
                                                <div class="col-md-4"><button class="btn btn-sm btn-secondary"
                                                        (click)="clearClaimant()">Clear</button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- <div class="row justify-content-end">
                                <div class="col-md-auto">
                                    <button class="btn btn-primary" (click)="AddNewClaimant()" >Add New Claimant</button>
                                </div>
                            </div> -->
                        <!-- Claimant List Start-->

                        <kendo-grid [data]="claimClaimantList" [scrollable]="'scrollable'">
                            <kendo-grid-column field="claimantName" title="Claimant Name"
                                [headerStyle]="{ width: '200px' }" [style]="{ width: '200px' }">
                                <!-- <ng-template kendoGridCellTemplate let-dataItem>
                                        <a *permission="'canEditAgentUser'"  (click)="AddEditAgent(dataItem.agentId)">{{dataItem?.agentCode}}</a>
                                    </ng-template> -->
                            </kendo-grid-column>
                            <kendo-grid-column field="claimantAddress" title="Claimant Address" [headerStyle]="{ width: '200px' }" [style]="{ width: '200px' }"></kendo-grid-column>
                            <kendo-grid-column field="insurerRef" title="Insurer Ref" [headerStyle]="{ width: '200px' }"
                                [style]="{ width: '200px' }"></kendo-grid-column>

                            <kendo-grid-column field="insurerHandler" title="Insurer Handler"
                                [headerStyle]="{ width: '200px' }" [style]="{ width: '200px' }">
                            </kendo-grid-column>

                            <kendo-grid-column field="reserveAmt" title="Reserve Amount"
                                [headerStyle]="{ width: '130px' }" [style]="{ width: '130px' }">
                            </kendo-grid-column>

                            <kendo-grid-column field="settledAmt" title="Settled Amount"
                                [headerStyle]="{ width: '130px' }" [style]="{ width: '130px' }">
                            </kendo-grid-column>


                            <kendo-grid-column field="action" title="Action" [headerStyle]="{ width: '60px' }"
                                [style]="{ width: '60px' }" *ngIf="policyStartDate<maxDate">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span (click)="editClaimant(dataItem)" class="cursor-pointer">
                                        <span class="material-symbols-outlined me-2 fs-20">
                                            edit
                                        </span>
                                    </span>
                                    <span (click)="deleteClaimant(dataItem)" class="cursor-pointer">
                                        <span class="material-symbols-outlined fs-20">
                                            delete
                                        </span>
                                    </span>
                                </ng-template>
                            </kendo-grid-column>

                            <ng-template kendoGridDetailTemplate let-dataItem>
                                <section>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p><strong>Loss Adjuster Ref:</strong> {{ dataItem.lossAdjusterRef }}
                                            </p>
                                        </div>
                                        <div class="col-md-6">
                                            <p><strong>Loss Adjuster Company:</strong> {{
                                                dataItem.lossAdjusterCompany }}
                                            </p>
                                        </div>
                                        <div class="col-md-6">
                                            <p><strong>Loss Adjuster Handler:</strong>
                                                {{dataItem.lossAdjusterHandler}}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p><strong>Loss Adjuster Email:</strong> {{ dataItem.lossAdjusterEmail
                                                }}</p>
                                        </div>
                                    </div>
                                </section>
                            </ng-template>
                        </kendo-grid>


                        <!-- Claimant List End-->
                    </div>

                    <!-- Claim Notes Tab -->
                    <div class="tab" *ngIf="currentTab === 2">
                        <h4>Claim Notes</h4>
                        <form novalidate [formGroup]="claimNoteForm">
                            <div class="border bg-light-gray mb-3 br-10 p-4">
                                <div class="row gy-4">
                                    <div class="col-md-3 position-relative">
                                        <label>Type</label>
                                        <kendo-combobox [openOnFocus]="noteType" #noteType class="w-100" placeholder="Note Type" [data]="noteTypeList"
                                            formControlName="noteType" [textField]="'text'" [valuePrimitive]="true"
                                            [valueField]="'value'">
                                        </kendo-combobox>
                                        <div class="validation-msg-label"
                                            *ngIf="claimNoteForm.controls?.noteType.touched && claimNoteForm.controls?.noteType.errors?.required">
                                            Required</div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>From</label>
                                        <input type="text" class="form-control" formControlName="noteFrom"
                                            maxlength="500" placeholder="Note From">
                                    </div>
                                    <div class="col-md-3">
                                        <label>To</label>
                                        <input type="text" class="form-control" formControlName="noteTo"
                                            maxlength="500" placeholder="Note To">
                                    </div>
                                    <div class="col-md-3 position-relative">
                                        <label>Date</label>
                                        <kendo-datepicker class="w-100" [format]="'dd/MM/yyyy'"
                                            formControlName="noteCreatedDate" [min]="minDate"
                                            [max]="maxDate"></kendo-datepicker>
                                        <div class="validation-msg-label" *ngIf="
                        claimNoteForm.controls?.noteCreatedDate.touched &&
                        claimNoteForm.controls?.noteCreatedDate.errors?.required">
                                            Required
                                        </div>
                                    </div>
                                    <div class="col-md-9 position-relative">
                                        <label>Description</label>
                                        <textarea name="" rows="5" id="" formControlName="note" maxlength="500"
                                            class="form-control form-control-textarea"></textarea>
                                            <div class="validation-msg-label" *ngIf="
                        claimNoteForm.controls?.note.touched &&
                        claimNoteForm.controls?.note.errors?.required">
                                            Required
                                        </div>
                                    </div>
                                    <div class="col-md-3 mt-5">
                                        <button class="btn btn-primary me-2"
                                            (click)="AddUpdateClaimNote()">Save</button>
                                        <button class="btn btn-sm btn-secondary" (click)="clearNote()">Clear</button>
                                    </div>

                                </div>
                                <!-- <div class="row justify-content-end">
                                    <div class="col-md-auto">
                                        <button class="btn btn-primary">Add New Note</button>
                                    </div>
                                </div> -->

                            </div>
                            <div class="mt-4">
                                <kendo-grid [data]="claimNoteList" [scrollable]="'scrollable'">
                                    <kendo-grid-column field="noteTypeDescription" title="Type">
                                    </kendo-grid-column>
                                    <kendo-grid-column field="noteFrom" title="From"></kendo-grid-column>
                                    <kendo-grid-column field="noteTo" title="To"></kendo-grid-column>
                                    <kendo-grid-column field="noteCreatedDate" title="Date">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            {{ dataItem.noteCreatedDate | date: "dd/MM/yyyy" }}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column field="note" title="Note">
                                    </kendo-grid-column>
                                    <kendo-grid-column field="action" title="Action" [headerStyle]="{ width: '60px' }"
                                        [style]="{ width: '60px' }" *ngIf="policyStartDate<maxDate">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <span (click)="editClaimNote(dataItem)"  class="cursor-pointer">
                                                <span class="material-symbols-outlined me-2 fs-20">
                                                    edit
                                                </span>
                                            </span>
                                            <span (click)="deleteClaimNote(dataItem)"  class="cursor-pointer">
                                                <span class="material-symbols-outlined fs-20">
                                                    delete
                                                </span>
                                            </span>
                                        </ng-template>
                                    </kendo-grid-column>
                                </kendo-grid>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- </div> -->
                <div id="nextprevious" class="mt-4">
                    <button *ngIf="!isEditMode && !isQuote" class="btn btn-secondary me-3" id="prevBtn"
                        (click)="Cancele()">Back</button>
                    <button *ngIf="isEditMode" class="btn btn-secondary me-3" id="prevBtn"
                        (click)="closeDialog(true)">Back</button>
                    <button class="btn btn-secondary me-3" id="prevBtn" (click)="nextPrev(-1)"
                        [disabled]="currentTab === 0">Previous</button>
                   
                    <button *ngIf="isEditMode" class="btn btn-primary" id="nextBtn" (click)="nextPrev(1)">
                        {{ currentTab === steps.length - 1 ? 'Save Claim' : 'Save And Next' }}
                    </button>

                    <button *ngIf="!isEditMode && !isQuote" class="btn btn-primary" id="nextBtn" (click)="nextPrev(1)">
                        {{ currentTab === steps.length - 1 ? 'Save Claim' : 'Save And Next' }}
                    </button>
                    <button *ngIf="!isEditMode && isQuote && (currentTab != 2)" class="btn btn-primary" id="nextBtn" (click)="nextPrev(1)">
                        {{ currentTab === steps.length - 1 ? 'Save Claim' : 'Save And Next' }}
                    </button>
                </div>
                <!-- <div id="text-message" *ngIf="currentTab >= steps.length">
                        Submission message goes here 
                        <p>All steps completed!</p>
                    </div>  -->
            </div>
        </div>
    </div>
</div>