import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { getExactDate, getExactDateWithoutTime, isBetween } from '@shared/utility';
import { ClaimStatusEnum, ProductType } from '@shared/utility/enum';
import { UserService } from '@app/services/user.service';
import { ToasterService } from '@shared/utility/toaster.service';

@Component({
  selector: 'app-add-claim',
  templateUrl: './add-claim.component.html',
  styleUrls: ['./add-claim.component.scss']
})
export class AddClaimComponent implements OnInit {
  @Input() isEditMode: boolean = false;
  @Input() Insurer: any;
  currentTab: number = 0;
  claimForm: FormGroup;
  claimClaimantsForm: FormGroup;
  claimNoteForm: FormGroup;
  policyId: any;
  policyNumber: any;
  productCode: any;
  policyInsurer: any;
  policyStartDate: Date;
  policyEndDate: Date;
  maxDate: Date;
  minDate: Date;
  productId: number;
  redirectPolicyId: number;
  totalReserve = "0";
  totalSettled = "0";
  totalClaims = "0";
  // policyId = 398;
  // policyNumber = "1";
  // productCode = "productCode";
  claimId: number;
  // policyInsurer = "AXA Life Insurance";
  // policyStartDate = new Date("2024-05-30 00:00:00.000");
  // policyEndDate = new Date("2025-05-30 00:00:00.000");
  claimStatusList: any;
  claimNatureList: any;
  noteTypeList: any;
  claimPerilList: any;
  claimClaimantList: [];
  claimNoteList: [];
  claimNoteId: any;
  claimClaimantId: any;
  isQuote:boolean=false;
  steps: string[] = ['Claim Summary', 'Claimant Details', 'Claim Notes'];
  // emailRegex = /^[a-zA-Z0-9_!"#$£%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
  emailRegex =/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;


  constructor(private fb: FormBuilder, private insuranceService: InsuranceService,
    private activatedRoute: ActivatedRoute, private toasterService: ToasterService, private confirMationDialogService: ConfirmationDialogService,
    @Optional() private activeModal: NgbActiveModal,
    private router: Router, private userService: UserService) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams['data']) {
      this.activatedRoute.queryParams.subscribe(params => {
        const decryptparams = this.insuranceService.decryptAndParseQueryStringForClaim(params.data);
        if (decryptparams !== undefined && decryptparams !== null
          && decryptparams.policyId !== null && decryptparams.policyId !== undefined && decryptparams.policyId !== 0) {
          this.policyId = decryptparams.policyId;
          this.policyEndDate = decryptparams.policyEndDate;
          this.maxDate = decryptparams.policyEndDate > new Date() ? new Date() : decryptparams.policyEndDate;
          this.minDate = decryptparams.policyStartDate > this.maxDate ? new Date() : decryptparams.policyStartDate;
          this.policyStartDate = decryptparams.policyStartDate;
          //this.policyInsurer = decryptparams.policyInsurer;
          this.productCode = decryptparams.productCode;
          this.policyNumber = decryptparams.policyNumber;
          this.claimId = decryptparams.claimId;
          this.productId = decryptparams.productId;
          this.redirectPolicyId = decryptparams.redirectPolicyId;
          this.isQuote= decryptparams.isQuote;
        } else {
          if (this.policyId <= 0 || this.policyId == null || this.policyId == undefined) {
            this.policyId = 0;
          }
        }
      });
    } else {
      if (this.policyId <= 0 || this.policyId == null || this.policyId == undefined) {
        this.policyId = 0;
      }
    }
    this.policyInsurer =this.Insurer;
    this.initForm();
    this.fillcombo();
    if (this.claimId > 0) {
      this.editClaim();
    }
    this.showTab(this.currentTab);
    if (this.policyStartDate > this.maxDate) {
      this.claimForm.disable();
      this.claimNoteForm.disable();
      this.claimClaimantsForm.disable();
    }
    this.claimForm.get('claimStatus')?.valueChanges.subscribe(value => {
      this.updateDateClosedValidators(value);
    });
  }

  ChangeStep(n: any) {
    if (this.claimId > 0) {
      this.showTab(n);
    }
    else {
      this.toasterService.warning("Please save claim details first");
    }
  }

  showTab(n: number): void {
    this.currentTab = n;
    this.fixStepIndicator(n);
  }

  closeDialog(result: boolean) {
    this.activeModal.close(result);
  }

  fillcombo() {
    this.getClaimStatusList();
    this.GetClaimNaturelist();
    this.GetClaimNoteTypeList();
    if (this.claimId) {
      this.getClaimClaimantList();
      this.GetClaimNotes();
    }
  }

  initForm() {
    this.claimForm = this.fb.group({
      claimStatus: [null, Validators.required],
      incidentDate: [null, Validators.required],
      dateClosed: [null],
      natureofClaim: [null, Validators.required],
      peril: [null, Validators.required],
      incidentLocation: [null],
      description: [null],
      additionalInfo: [null]
    });

    this.claimClaimantsForm = this.fb.group({
      claimantName: [null],
      claimantAddress: [null],
      insurerRef: [null],
      insurerHandler: [null],
      lossAdjusterRef: [null],
      lossAdjusterCompany: [null],
      lossAdjusterHandler: [null],
      lossAdjusterEmail: ['', [Validators.pattern(this.emailRegex), this.userService.noWhitespaceValidator]],
      reserveAmt: ['0.00'],
      settledAmt: ['0.00'],
    });

    this.claimNoteForm = this.fb.group({
      noteType: [null, Validators.required],
      noteFrom: [null],
      noteTo: [null],
      note: [null, Validators.required],
      noteCreatedDate: [getExactDateWithoutTime(new Date()), Validators.required]
    });
  }

  updateDateClosedValidators(claimStatus: number) {
    const dateClosedControl = this.claimForm.get('dateClosed');
    if (claimStatus === 2) {
      dateClosedControl?.setValidators([Validators.required]);
    } else {
      dateClosedControl?.clearValidators();
    }
    dateClosedControl?.updateValueAndValidity();
  }

  getClaimStatusList() {
    this.insuranceService.GetClaimStatus().subscribe((data: any): void => {
      this.claimStatusList = data;
    });
  }

  GetClaimNaturelist() {
    this.insuranceService.GetClaimNaturelistByProductId(this.productId).subscribe((list: any): void => {
      this.claimNatureList = list;
      if (this.claimNatureList.length > 0 && this.claimId <= 0 && this.claimNatureList.length == 1) {
        this.claimForm.controls["natureofClaim"].setValue(this.claimNatureList[0].value);
        this.onChangeNatureOfClaim(this.claimNatureList[0].value);
      }
    });
  }

  onChangeStatus(event: any) {
    if (event == ClaimStatusEnum.Closed) {
      this.claimForm.controls["dateClosed"].setValidators(Validators.required);
    }
    else {
      this.claimForm.controls["dateClosed"].clearValidators();
      this.claimForm.controls["dateClosed"].reset();
    }
    this.claimForm.controls["dateClosed"].updateValueAndValidity();
  }

  GetClaimNoteTypeList() {
    this.insuranceService.GetClaimNoteType().subscribe((data: any): void => {
      this.noteTypeList = data;
    });
  }

  onChangeNatureOfClaim(event: number) {
    this.claimForm.controls.peril.reset();
    this.insuranceService.GetClaimPerillistByClaimNature(event).subscribe((data: any): void => {
      this.claimPerilList = data;
      if (this.claimPerilList.length > 0 && this.claimId <= 0) {
        this.claimForm.controls["peril"].patchValue(this.claimPerilList[0].value);
      }
    });
  }

  nextPrev(n: number): void {
    if (!this.validateForm()) return;

    if (this.currentTab === 0) {
      // Perform initial tab validation
      if (this.policyId === undefined || this.policyId === 0 || this.policyId === null) {
        this.toasterService.error("Policy is required.");
        return;
      }
      // if (this.policyInsurer === undefined || this.policyInsurer === '' || this.policyInsurer === null) {
      //   this.toasterService.error("Policy Insurer is required.");
      //   return;
      // }
      if (this.policyNumber === undefined || this.policyNumber === '' || this.policyNumber === null) {
        this.toasterService.error("Policy Number is required.");
        return;
      }
      if (this.productCode === undefined || this.productCode === '' || this.productCode === null) {
        this.toasterService.error("Product Code is required.");
        return;
      }
      if (this.policyStartDate === undefined || this.policyStartDate === null || this.policyEndDate === undefined || this.policyEndDate === null) {
        this.toasterService.error("Policy Range is required.");
        return;
      }
      this.saveClaim();
    }
    this.currentTab += n;
    if (this.currentTab == 1 && this.claimId > 0) {
      this.getClaimClaimantList();
    }
    if (this.currentTab == 2 && this.claimId > 0) {
      this.GetClaimNotes();
    }
    if (this.currentTab >= this.steps.length) {
      if (this.activeModal) {
        this.closeDialog(true);
      }
      else {
        this.Cancele();
      }
    } else {
      this.showTab(this.currentTab);
    }
  }

  // private handleTabNavigation() {
  //   if (this.currentTab === 1 && this.claimId > 0) {
  //     this.getClaimClaimantList();
  //   }
  //   if (this.currentTab === 2 && this.claimId > 0) {
  //     this.GetClaimNotes();
  //   }
  //   if (this.currentTab >= this.steps.length) {
  //     if (this.activeModal) {
  //       this.closeDialog(true);
  //     }
  //     else {
  //       this.Cancele();
  //     }
  //   } else {
  //     this.showTab(this.currentTab);
  //   }
  // }

  async saveClaim() {

    if (this.currentTab == 0) {
      let isbetween = !isBetween(getExactDate(this.claimForm.controls['incidentDate']?.value), this.policyStartDate, this.policyEndDate);
      if (!isBetween(getExactDate(this.claimForm.controls['incidentDate']?.value), this.policyStartDate, this.policyEndDate)) {
        this.toasterService.error("Incident date should be between policy start and end date.");
        return;
      }
      const objClaimSave = Object.assign({});
      objClaimSave.claimId = this.claimId > 0 ? this.claimId : 0;
      objClaimSave.status = this.claimForm.controls['claimStatus']?.value;
      objClaimSave.incidentDate = getExactDate(this.claimForm.controls['incidentDate']?.value);
      objClaimSave.dateClosed = this.claimForm.controls['dateClosed']?.value ? getExactDate(this.claimForm.controls['dateClosed']?.value) : null;
      objClaimSave.claimNatureId = this.claimForm.controls['natureofClaim']?.value;
      objClaimSave.claimPerilId = this.claimForm.controls['peril']?.value;
      objClaimSave.incidentLocation = this.claimForm.controls['incidentLocation']?.value;
      objClaimSave.description = this.claimForm.controls['description']?.value;
      objClaimSave.additionalInfo = this.claimForm.controls['additionalInfo']?.value;
      objClaimSave.insCompany = this.policyInsurer;
      objClaimSave.policyId = this.policyId;
      objClaimSave.policyNumber = this.policyNumber;
      objClaimSave.productCode = this.productCode

      var response = await this.insuranceService.AddUpdateClaim(objClaimSave).toPromise();
      if (response) {
        this.claimId = response.payload.claimId;
        if (this.currentTab >= this.steps.length) {
          this.closeDialog(true);
        }
      }
    }
  }

  AddNewClaimant() {
    if (this.claimClaimantsForm.valid) {
      const objClaimSaveClaimant = Object.assign({});
      objClaimSaveClaimant.claimClaimantId = this.claimClaimantId > 0 ? this.claimClaimantId : 0;
      objClaimSaveClaimant.claimantName = this.claimClaimantsForm.controls['claimantName']?.value;
      objClaimSaveClaimant.claimantAddress = this.claimClaimantsForm.controls['claimantAddress']?.value;
      objClaimSaveClaimant.insurerRef = this.claimClaimantsForm.controls['insurerRef']?.value;
      objClaimSaveClaimant.insurerHandler = this.claimClaimantsForm.controls['insurerHandler']?.value;
      objClaimSaveClaimant.lossAdjusterRef = this.claimClaimantsForm.controls['lossAdjusterRef']?.value;
      objClaimSaveClaimant.lossAdjusterCompany = this.claimClaimantsForm.controls['lossAdjusterCompany']?.value;
      objClaimSaveClaimant.lossAdjusterHandler = this.claimClaimantsForm.controls['lossAdjusterHandler']?.value;
      objClaimSaveClaimant.lossAdjusterEmail = this.claimClaimantsForm.controls['lossAdjusterEmail']?.value;
      objClaimSaveClaimant.reserveAmt = this.claimClaimantsForm.controls['reserveAmt']?.value ? this.claimClaimantsForm.controls['reserveAmt']?.value : 0;
      objClaimSaveClaimant.settledAmt = this.claimClaimantsForm.controls['settledAmt']?.value ? this.claimClaimantsForm.controls['settledAmt']?.value : 0;
      objClaimSaveClaimant.claimId = this.claimId;
      this.insuranceService.AddUpdateClaimClaimant(objClaimSaveClaimant).subscribe((data: any): void => {
        if (data.success) {
          if (objClaimSaveClaimant.claimClaimantId > 0) {
            this.toasterService.success('Claimant updated successfully.');
          }
          else {
            this.toasterService.success('Claimant added successfully.');
          }
        }
        this.clearClaimant();
        this.getClaimClaimantList();
      });
    }
    else {
      this.insuranceService.validateAllFormFields(this.claimClaimantsForm)
    }
  }

  AddUpdateClaimNote() {
    if (this.claimNoteForm.valid) {
      const objClaimSaveNote = Object.assign({});
      objClaimSaveNote.claimNoteId = (this.claimNoteId > 0) ? this.claimNoteId : 0;
      objClaimSaveNote.noteType = this.claimNoteForm.controls['noteType']?.value;
      objClaimSaveNote.noteFrom = this.claimNoteForm.controls['noteFrom']?.value;
      objClaimSaveNote.noteTo = this.claimNoteForm.controls['noteTo']?.value;
      objClaimSaveNote.note = this.claimNoteForm.controls['note']?.value;
      objClaimSaveNote.claimId = this.claimId;
      objClaimSaveNote.noteCreatedDate = getExactDate(this.claimNoteForm.controls['noteCreatedDate']?.value);
      this.insuranceService.AddUpdateClaimNote(objClaimSaveNote).subscribe((data: any): void => {
        this.clearNote();
        this.GetClaimNotes();
        if (data.success) {
          if (objClaimSaveNote.claimNoteId > 0) {
            this.toasterService.success('Note updated successfully.');
          }
          else {
            this.toasterService.success('Note added successfully.');
          }
        }
      });
    }
    else {
      this.insuranceService.validateAllFormFields(this.claimNoteForm)
    }
  }

  clearClaimant() {
    this.claimClaimantId = 0;
    this.claimClaimantsForm.reset();
  }
  clearNote() {
    this.claimNoteId = 0;
    this.claimNoteForm.reset();
  }

  GetClaimNotes() {
    this.insuranceService.GetClaimNotes(this.claimId).subscribe((data: any): void => {
      this.claimNoteList = data;
    });
  }

  validateForm(): boolean {
    let valid = true;
    if (this.currentTab == 0) {
      this.insuranceService.validateAllFormFields(this.claimForm)
      if (this.claimForm.valid) {
        return true;
      }
      else {
        return false;
      }
    }
    // if (this.currentTab == 1) {
    //   this.insuranceService.validateAllFormFields(this.claimClaimantsForm)
    //   if (this.claimClaimantsForm.valid) {
    //     return true;
    //   }
    //   else {
    //     return false;
    //   }
    // }
    // if (this.currentTab == 2) {
    //   this.insuranceService.validateAllFormFields(this.claimNoteForm)
    //   if (this.claimNoteForm.valid) {
    //     return true;
    //   }
    //   else {
    //     return false;
    //   }
    // }


    // Add your validation logic here if needed
    return valid;
  }

  fixStepIndicator(n: number): void {
    const steps = document.getElementsByClassName('step');
    for (let i = 0; i < steps.length; i++) {
      steps[i].classList.remove('active');
    }
    if (steps[n]) {
      steps[n].classList.add('active');
    }
  }

  getClaimClaimantList() {
    this.insuranceService.GetClaimClaimantList(this.claimId).subscribe((data: any): void => {
      this.claimClaimantList = data;
      this.GetPolicyTotalClaimAmountByClaim();
    });
  }

  Cancele() {
    let encryptedqueryparams: { policyId: number; };
    encryptedqueryparams = { policyId: this.redirectPolicyId ? this.redirectPolicyId : this.policyId }
    console.log(this.insuranceService.encryptData(encryptedqueryparams))
    if (this.productId == ProductType.ELPL) {
      this.router.navigate(['product/policy-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    else if (this.productId == ProductType.Shop || this.productId == ProductType.Off || this.productId == ProductType.Lei || this.productId == ProductType.Fcc) {
      this.router.navigate(['product/ccpolicy-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    } else if (this.productId == ProductType.Car) {
      this.router.navigate(['product/car-policy-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    else if (this.productId == ProductType.Sb) {
      this.router.navigate(['product/sb-policy-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    else if (this.productId == ProductType.Rei) {
      this.router.navigate(['product/reipolicy-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    else if (this.productId == ProductType.Bbg) {
      this.router.navigate(['product-tm/bbg-policy-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    else if (this.productId == ProductType.Pi) {
      this.router.navigate(['product-tm/pi-policy-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    else if (this.productId == ProductType.Pa) {
      this.router.navigate(['product-tm/pa-policy-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    else if (this.productId == ProductType.Poi) {
      this.router.navigate(['property-owner/poi-policy-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
  }

  async editClaim() {
    var claim = await this.insuranceService.GetClaimDetail(this.claimId).toPromise();
    if (claim && claim.payload) {
      this.onChangeNatureOfClaim(claim.payload.claimNatureId);
      this.claimForm.controls['claimStatus'].patchValue(claim.payload.status);
      this.claimForm.controls['incidentLocation'].patchValue(claim.payload.incidentLocation);
      this.claimForm.controls['description'].patchValue(claim.payload.description);
      this.claimForm.controls['additionalInfo'].patchValue(claim.payload.additionalInfo);
      this.claimForm.controls['incidentDate'].patchValue(getExactDateWithoutTime(claim.payload.incidentDate));
      claim.payload.dateClosed ? this.claimForm.controls['dateClosed'].patchValue(getExactDate(claim.payload.dateClosed)) : null;
      this.claimForm.controls['natureofClaim'].patchValue(claim.payload.claimNatureId);
      this.claimForm.controls['peril'].patchValue(claim.payload.claimPerilId);
    }
  }


  editClaimant(dataItem: any) {
    this.claimClaimantId = dataItem.claimClaimantId;
    this.claimClaimantsForm.controls['claimantName'].patchValue(dataItem.claimantName);
    this.claimClaimantsForm.controls['claimantAddress'].patchValue(dataItem.claimantAddress);
    this.claimClaimantsForm.controls['insurerRef'].patchValue(dataItem.insurerRef);
    this.claimClaimantsForm.controls['insurerHandler'].patchValue(dataItem.insurerHandler);
    this.claimClaimantsForm.controls['lossAdjusterRef'].patchValue(dataItem.lossAdjusterRef);
    this.claimClaimantsForm.controls['lossAdjusterCompany'].patchValue(dataItem.lossAdjusterCompany);
    this.claimClaimantsForm.controls['lossAdjusterHandler'].patchValue(dataItem.lossAdjusterHandler);
    this.claimClaimantsForm.controls['lossAdjusterEmail'].patchValue(dataItem.lossAdjusterEmail);
    this.claimClaimantsForm.controls['reserveAmt'].patchValue(dataItem.reserveAmt);
    this.claimClaimantsForm.controls['settledAmt'].patchValue(dataItem.settledAmt);
  }

  deleteClaimNote(dataItem: any) {
    this.confirMationDialogService
      .confirm(
        "Delete Note",
        "Are you sure that you want to delete this permanently?",
        "Yes",
        "No"
      )
      .then((r) => {
        if (r) {
          this.claimNoteId = 0;
          this.insuranceService.DeleteClaimNote(dataItem.claimNoteId).subscribe((data: any): void => {
            this.GetClaimNotes();
          });
        }
      });
  }

  editClaimNote(dataItem: any) {
    this.claimNoteId = dataItem.claimNoteId;
    this.claimNoteForm.controls['noteType'].patchValue(dataItem.noteType);
    this.claimNoteForm.controls['noteFrom'].patchValue(dataItem.noteFrom);
    this.claimNoteForm.controls['noteTo'].patchValue(dataItem.noteTo);
    this.claimNoteForm.controls['noteCreatedDate'].patchValue(getExactDateWithoutTime(dataItem.noteCreatedDate));
    this.claimNoteForm.controls['note'].patchValue(dataItem.note);
  }

  deleteClaimant(dataItem: any) {
    this.confirMationDialogService
      .confirm(
        "Delete Claimant",
        "Are you sure that you want to delete this permanently?",
        "Yes",
        "No"
      )
      .then((r) => {
        if (r) {
          this.claimClaimantId = 0;
          this.insuranceService.DeleteClaimClaimant(dataItem.claimClaimantId).subscribe((data: any): void => {
            this.getClaimClaimantList();
          });
        }
      });
  }

  GetPolicyTotalClaimAmountByClaim() {
    this.insuranceService.GetPolicyTotalClaimAmountByClaim(this.claimId).subscribe((data: any): void => {
      if (data) {
        this.totalClaims = data.totalClaims
        this.totalSettled = data.totalSettled
        this.totalReserve = data.totalReserve
      }
    });
  }
}
